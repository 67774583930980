.BlockSpinner {
  position: relative;
  width: 89px;
  height: 89px;
}
.BlockSpinner::before {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 25px;
  height: 25px;
  content: "";
  box-shadow: 32px 0 0 var(--teal-500-00), 64px 0 0 var(--teal-500-00),
    0 -32px 0 var(--teal-500-00), 32px -32px 0 var(--teal-500-00),
    64px -32px 0 var(--teal-500-00), 0 -64px var(--teal-500-00),
    32px -64px var(--teal-500-00), 64px -64px rgba(201, 151, 0, 0);
  animation: loader 1.5s linear forwards infinite;
}
.BlockSpinner::after {
  position: absolute;
  bottom: 10px;
  left: 0;
  display: block;
  width: 25px;
  height: 25px;
  background-color: var(--mint-500);
  opacity: 0;
  content: "";
  animation: base 1.5s linear forwards infinite;
}
@keyframes base {
  0% {
    bottom: 10px;
    opacity: 0;
  }
  5%,
  50% {
    bottom: 0;
    opacity: 1;
  }
  55%,
  100% {
    bottom: -10px;
    opacity: 0;
  }
}
@keyframes loader {
  0% {
    box-shadow: 32px -10px var(--teal-500-00), 64px 0 var(--teal-500-00),
      0 -32px var(--teal-500-00), 32px -32px var(--teal-500-00),
      64px -32px var(--teal-500-00), 0 -64px var(--teal-500-00),
      32px -64px var(--teal-500-00), 64px -64px rgba(201, 151, 0, 0);
  }
  5% {
    box-shadow: 32px -10px var(--teal-500-00), 64px 0 var(--teal-500-00),
      0 -32px var(--teal-500-00), 32px -32px var(--teal-500-00),
      64px -32px var(--teal-500-00), 0 -64px var(--teal-500-00),
      32px -64px var(--teal-500-00), 64px -64px rgba(201, 151, 0, 0);
  }
  10% {
    box-shadow: 32px 0 var(--teal-500), 64px -10px var(--teal-500-00),
      0 -32px var(--teal-500-00), 32px -32px var(--teal-500-00),
      64px -32px var(--teal-500-00), 0 -64px var(--teal-500-00),
      32px -64px var(--teal-500-00), 64px -64px rgba(201, 151, 0, 0);
  }
  15% {
    box-shadow: 32px 0 var(--teal-500), 64px 0 var(--teal-500),
      0 -42px var(--teal-500-00), 32px -32px var(--teal-500-00),
      64px -32px var(--teal-500-00), 0 -64px var(--teal-500-00),
      32px -64px var(--teal-500-00), 64px -64px rgba(201, 151, 0, 0);
  }
  20% {
    box-shadow: 32px 0 var(--teal-500), 64px 0 var(--teal-500),
      0 -32px var(--teal-500), 32px -42px var(--teal-500-00),
      64px -32px var(--teal-500-00), 0 -64px var(--teal-500-00),
      32px -64px var(--teal-500-00), 64px -64px rgba(201, 151, 0, 0);
  }
  25% {
    box-shadow: 32px 0 var(--teal-500), 64px 0 var(--teal-500),
      0 -32px var(--teal-500), 32px -32px var(--teal-500),
      64px -42px var(--teal-500-00), 0 -64px var(--teal-500-00),
      32px -64px var(--teal-500-00), 64px -64px rgba(201, 151, 0, 0);
  }
  30% {
    box-shadow: 32px 0 var(--teal-500), 64px 0 var(--teal-500),
      0 -32px var(--teal-500), 32px -32px var(--teal-500),
      64px -32px var(--teal-500), 0 -74px var(--teal-500-00),
      32px -64px var(--teal-500-00), 64px -64px rgba(201, 151, 0, 0);
  }
  35% {
    box-shadow: 32px 0 var(--teal-500), 64px 0 var(--teal-500),
      0 -32px var(--teal-500), 32px -32px var(--teal-500),
      64px -32px var(--teal-500), 0 -64px var(--teal-500),
      32px -74px var(--teal-500-00), 64px -64px rgba(201, 151, 0, 0);
  }
  40% {
    box-shadow: 32px 0 var(--teal-500), 64px 0 var(--teal-500),
      0 -32px var(--teal-500), 32px -32px var(--teal-500),
      64px -32px var(--teal-500), 0 -64px var(--teal-500),
      32px -64px var(--teal-500), 64px -74px rgba(201, 151, 0, 0);
  }
  45%,
  55% {
    box-shadow: 32px 0 var(--teal-500), 64px 0 var(--teal-500),
      0 -32px var(--teal-500), 32px -32px var(--teal-500),
      64px -32px var(--teal-500), 0 -64px var(--teal-500),
      32px -64px var(--teal-500), 64px -64px var(--teal-900);
  }
  60% {
    box-shadow: 32px 10px var(--teal-500-00), 64px 0 var(--teal-500),
      0 -32px var(--teal-500), 32px -32px var(--teal-500),
      64px -32px var(--teal-500), 0 -64px var(--teal-500),
      32px -64px var(--teal-500), 64px -64px var(--teal-900);
  }
  65% {
    box-shadow: 32px 10px var(--teal-500-00), 64px 10px var(--teal-500-00),
      0 -32px var(--teal-500), 32px -32px var(--teal-500),
      64px -32px var(--teal-500), 0 -64px var(--teal-500),
      32px -64px var(--teal-500), 64px -64px var(--teal-900);
  }
  70% {
    box-shadow: 32px 10px var(--teal-500-00), 64px 10px var(--teal-500-00),
      0 -22px var(--teal-500-00), 32px -32px var(--teal-500),
      64px -32px var(--teal-500), 0 -64px var(--teal-500),
      32px -64px var(--teal-500), 64px -64px var(--teal-900);
  }
  75% {
    box-shadow: 32px 10px var(--teal-500-00), 64px 10px var(--teal-500-00),
      0 -22px var(--teal-500-00), 32px -22px var(--teal-500-00),
      64px -32px var(--teal-500), 0 -64px var(--teal-500),
      32px -64px var(--teal-500), 64px -64px var(--teal-900);
  }
  80% {
    box-shadow: 32px 10px var(--teal-500-00), 64px 10px var(--teal-500-00),
      0 -22px var(--teal-500-00), 32px -22px var(--teal-500-00),
      64px -22px var(--teal-500-00), 0 -64px var(--teal-500),
      32px -64px var(--teal-500), 64px -64px var(--teal-900);
  }
  85% {
    box-shadow: 32px 10px var(--teal-500-00), 64px 10px var(--teal-500-00),
      0 -22px var(--teal-500-00), 32px -22px var(--teal-500-00),
      64px -22px var(--teal-500-00), 0 -54px var(--teal-500-00),
      32px -64px var(--teal-500), 64px -64px var(--teal-900);
  }
  90% {
    box-shadow: 32px 10px var(--teal-500-00), 64px 10px var(--teal-500-00),
      0 -22px var(--teal-500-00), 32px -22px var(--teal-500-00),
      64px -22px var(--teal-500-00), 0 -54px var(--teal-500-00),
      32px -54px var(--teal-500-00), 64px -64px var(--teal-900);
  }
  95%,
  100% {
    box-shadow: 32px 10px var(--teal-500-00), 64px 10px var(--teal-500-00),
      0 -22px var(--teal-500-00), 32px -22px var(--teal-500-00),
      64px -22px var(--teal-500-00), 0 -54px var(--teal-500-00),
      32px -54px var(--teal-500-00), 64px -54px rgba(201, 151, 0, 0);
  }
}
