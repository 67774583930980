:root {
  /* Teals */
  --teal-900: #02373e;
  --teal-800: #024048;
  --teal-700: #024a52;
  --teal-600: #03535d;
  --teal-500: #035c67;
  --teal-500-00: #035c6700;
  --teal-400: #357d85;
  --teal-300: #689da4;
  --teal-200: #9abec2;
  --teal-100: #cddee1;
  --teal-50: #e6eff0;

  /* Dark Teals */
  --dark-teal-900: #001c1e;
  --dark-teal-800: #002123;
  --dark-teal-700: #002628;
  --dark-teal-600: #002a2d;
  --dark-teal-500: #002f32;
  --dark-teal-400: #33595b;
  --dark-teal-300: #668284;
  --dark-teal-200: #99acad;
  --dark-teal-100: #ccd5d6;

  /* Mints */
  --mint-900: #259973;
  --mint-800: #2bb386;
  --mint-700: #32cc9a;
  --mint-600: #38e6ad;
  --mint-500: #3effc0;
  --mint-400: #65ffcd;
  --mint-300: #8bffd9;
  --mint-200: #b2ffe6;
  --mint-100: #d8fff2;

  /* Accent Greens */
  --accent-green-900: #00564f;
  --accent-green-800: #00645c;
  --accent-green-700: #007269;
  --accent-green-600: #008176;
  --accent-green-500: #008f83;
  --accent-green-400: #33a59c;
  --accent-green-300: #66bcb5;
  --accent-green-200: #99d2cd;
  --accent-green-100: #cce9e6;
}
