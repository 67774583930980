.PreferredTimes {
  text-align: center;
}

.PreferredTimes__columns {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  position: relative;
}

.PreferredTimes__column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.PreferredTimes__set_header {
  font-size: 0.75em;
  line-height: 28px;
}

.PreferredTimes__time_header {
  font-size: 11px;
  line-height: 1.1;
  padding: 4px;
}

.PreferredTimes__cell {
  margin: 1px;
  border-radius: 4px;
  background-color: #ebebeb;
  transition: background-color 150ms, color 150ms;
  user-select: none;
}
.PreferredTimes__time_body {
  height: 28px;
  line-height: 28px;
}

.PreferredTimes__selected {
  color: #52d169;
  background-color: #b9edb4;
}

.PreferredTimes__unselected {
  color: #f18989;
  background-color: #f9cfcf;
}
