.PreferredDays__instructions_container {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(100%, -50%);
  animation: hint infinite 1s ease-in-out;
}

.PreferredDays__instructions {
  padding: 1em;
  border: 1px solid rgb(127, 181, 242);
  border-radius: 0.5em;
  background-color: rgb(197, 227, 250);
  font-size: 0.75em;
}

@keyframes hint {
  0% {
    transform: translate(110%, -50%);
  }
  50% {
    transform: translate(100%, -50%);
  }
  100% {
    transform: translate(110%, -50%);
  }
}

.PreferredDays__columns {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.PreferredDays__column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.PreferredDays__cell {
  margin: 1px;
  border-radius: 4px;
  background-color: #ebebeb;
  height: 32px;
  line-height: 32px;
  user-select: none;
  transition: background-color 150ms, color 150ms;
}

.PreferredDays__selected {
  color: #52d169;
  background-color: #b9edb4;
}

.PreferredDays__unselected {
  color: #f18989;
  background-color: #f9cfcf;
}
